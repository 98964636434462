import { System, Entity } from "aframe";

interface IMethodSystemState {
  callbacks: { [name: string]: (...args: any[]) => void };
}

export interface IMethodSystemAframe extends System {
  state: IMethodSystemState;
  registerCallback(name: string, fn: (...args: any[]) => void): void;
  getCallback(name: string): ((...args: any[]) => void) | undefined;
}
  
  const methodSystemState = {
    callbacks: {},
  };
  
  const MethodSystem: { name: string; val: IMethodSystemAframe } = {
    name: "method-system",
    val: {
      data: {},
      schema: {},
      state: methodSystemState,
      el: document.createElement('a-entity') as Entity,
  
      init(): void {
      },
  
      pause() {},
  
      play() {},
  
      registerCallback(name, fn) {
        this.state.callbacks[name] = fn;
      },
  
      getCallback(name) {
        return this.state.callbacks[name];
      },
    },
  };
  
  export { MethodSystem };
  